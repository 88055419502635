export default defineNuxtRouteMiddleware((to) => {
  const { $pinia } = useNuxtApp();
  const { user } = storeToRefs(useUserStore($pinia));
  const { tokenUser } = storeToRefs(useTokenUserStore($pinia));

  if (!user.value && !tokenUser.value) {
    return;
  }

  if (to.path !== "/onboarding/checkout") {
    return navigateTo("/onboarding/checkout");
  }
});
